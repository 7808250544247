<template>
  <div class="knowledgeStore">
    <a-alert
      type="warning"
      show-icon
      message="从第三方平台采集的笔记，入库时记录当前笔记数据，笔记发布7日后再自动更新一次；可人工筛选分发到公共笔记库和图片作为运营再生产的原料。"
    />
    <a-back-top :visibilityHeight="120" style="right: 10px" />
    <div class="tab-item">
      <a-row type="flex" justify="space-between" class="filter-wrapper">
        <a-col style="margin-top: 15px">
          <a-space>
            <div>
              <a-select v-model="searchForm.timeType" style="width: 150px">
                <a-select-option v-for="item in TIME_TYPE_LIST" :key="item.value" :value="item.value">
                  {{ item.label }}
                </a-select-option>
              </a-select>
              <a-date-picker v-model="pickerTime" placeholder="年/月/日" style="width: 180px; margin-top: -1px" />
            </div>
            <a-tooltip :title="searchForm.searchNote ? '笔记名称/ID' : ''">
              <a-input
                style="width: 180px"
                v-model.trim="searchForm.searchNote"
                :placeholder="'笔记名称/ID'"
                allowClear
              />
            </a-tooltip>
            <a-tooltip :title="searchForm.searchAuthor ? '媒体号/ID' : ''">
              <a-input
                style="width: 160px"
                v-model.trim="searchForm.searchAuthor"
                :placeholder="'媒体号/ID'"
                allowClear
              />
            </a-tooltip>
            <a-tooltip :title="searchForm.creator ? '添加人' : ''">
              <a-select
                style="width: 160px"
                v-model="searchForm.creator"
                placeholder="请选择添加人"
                show-search
                option-filter-prop="children"
                allowClear
              >
                <a-select-option v-for="item in creatorList" :key="item.username" :value="item.username">{{
                  item.name
                }}</a-select-option>
              </a-select>
            </a-tooltip>
            <a-tooltip :title="searchForm.crawlTaskId ? '任务ID' : ''">
              <a-input style="width: 180px" v-model.trim="searchForm.crawlTaskId" :placeholder="'任务ID'" allowClear />
            </a-tooltip>
            <a-select style="width: 180px" v-model="searchForm.handleStatus" placeholder="处理状态" allowClear>
              <a-select-option v-for="(v, i) in statusList" :key="i" :value="v.value">{{ v.label }}</a-select-option>
            </a-select>
          </a-space>
        </a-col>
      </a-row>
      <a-row type="flex" justify="space-between">
        <a-space>
          <a-button type="primary" icon="search" @click="onSearch" style="width: 100px"> 查询 </a-button>
          <a-button icon="redo" @click="onReset" style="width: 100px"> 重置 </a-button>
        </a-space>
        <div>
          <a-tooltip placement="left">
            <template slot="title">
              <div>互动量：点赞、收藏、评论的总和</div>
              <div>笔记编辑时间：在小红书编辑笔记的时间。</div>
              <div>
                数据更新：笔记发布后7天内保持每天
                9:00~10:00更新更新数据，例如发布后第3天入库，则之后连续4天更新数据，发布当天为第0天。
              </div>
              <div>添加时间：添加这条笔记入库的时间</div>
            </template>
            <div style="cursor: pointer">
              <a-icon type="question-circle" style="margin-right: 10px" />数据说明
            </div></a-tooltip
          >
        </div>
      </a-row>

      <a-alert style="margin-top: 16px; padding: 5px 15px" type="info" v-show="pendingCount > 0">
        <div slot="description">
          <a-icon type="info-circle" theme="twoTone" two-tone-color="#faad14" style="margin-right: 10px" />新采集
          {{ pendingCount }} 条笔记，需安排入库<a-button type="link" @click="handleTipsClick">立即查看</a-button>
        </div>
      </a-alert>
      <div class="table-line line-flex" v-show="selectedRowKeys.length">
        <div class="line-flex">
          <div>
            已选<span style="margin: 0 2px; color: #378ffd">{{ selectedRowKeys.length }}</span
            >项
          </div>
          <a-space>
            <a-button type="link" @click="selectedRowKeys = []">清空</a-button>
            <a-popconfirm title="是否删除？" ok-text="确定" cancel-text="取消" @confirm="confirmMove">
              <a-button type="default" :loading="btnLoading">批量删除</a-button>
            </a-popconfirm>
            <a-button type="default" :loading="btnLoading" @click="handleBatchPush">批量推送</a-button>
            <a-button type="default" :loading="btnLoading" @click="handleBatchIgnore">批量忽略</a-button>
          </a-space>
        </div>
      </div>

      <a-table
        v-tableSticky
        row-key="id"
        :loading="tableLoading"
        :columns="columns"
        :data-source="dataList"
        :rowKey="(record) => record.id"
        :row-selection="rowSelection"
        :pagination="false"
        @change="onTableChange"
        :scroll="{ x: 1800 }"
      >
        <div slot="writingPreview" slot-scope="text, record">
          <div class="writingPreview">
            <div class="previewLeft" @click="openCheckWriting(record)">
              <img :src="record.previewImageUrl" alt="图片" style="cursor: pointer" referrerpolicy="no-referrer" />
            </div>
            <div class="previewRight">
              <div class="rightTitle" @click="openCheckWriting(record)" :title="record.noteTitle">
                {{ record.noteTitle }}
              </div>
              <div>
                <div class="media-info">
                  <a-tooltip>
                    <template slot="title"
                      >{{ record.nickname }}{{ record.authorCode ? `- ${record.authorCode}` : '' }}</template
                    >
                    <div style="display: flex">
                      <span class="logo"><img src="@/assets/icon/xhs_logo.png" alt="" /></span
                      ><span class="nickname-text" @click="handleJumpXHS(record)">{{ record.nickname }}</span>
                    </div>
                  </a-tooltip>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div slot="handleStatus" slot-scope="text, record">
          {{ getStatusText(record.handleStatus) }}
        </div>
        <div slot="operation" slot-scope="text, record">
          <template v-if="record.handleStatus === 'PENDING'">
            <a-space>
              <a @click="handleBatchPush(record)"> 推送至选题库 </a>
              <a-dropdown>
                <a class="ant-dropdown-link" @click="(e) => e.preventDefault()"> 更多 <a-icon type="down" /> </a>
                <a-menu slot="overlay">
                  <a-menu-item>
                    <a-button type="link" @click="handleBatchIgnore(record)"> 忽略 </a-button>
                  </a-menu-item>
                  <a-menu-item>
                    <a-popconfirm
                      placement="topRight"
                      title="确定删除？"
                      ok-text="删除"
                      cancel-text="取消"
                      @confirm="handleDel(record.id)"
                    >
                      <a-button type="link">删除</a-button>
                    </a-popconfirm>
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </a-space>
          </template>
          <template v-else>
            <a-popconfirm
              placement="topRight"
              title="确定删除？"
              ok-text="删除"
              cancel-text="取消"
              @confirm="handleDel(record.id)"
            >
              <a>删除</a>
            </a-popconfirm>
          </template>
        </div>
      </a-table>

      <base-pagination
        :current-page="pagination.current"
        :page-size="pagination.pageSize"
        :total="pagination.total"
        @onChange="handlePaginationChange"
        @onShowSizeChange="handlePaginationChange"
        :options="['30', '50', '100']"
      />
    </div>

    <!-- 查看/编辑抽屉 -->
    <writtingDrawer v-if="showDrawer" :readOnly="true" @closeDrawer="handleDrawerClose" :knowledgeId="knowledgeId" />

    <!-- 批量推送 -->
    <BatchPushToTopicModal ref="BatchPushToTopicModal" @success="handlePushSuccess" />
  </div>
</template>

<script>
import api from '@/api/xhsAgencyApi';
import writtingDrawer from '@/components/xhsAgencyTool/writtingDrawer.vue';
import BatchPushToTopicModal from '@/components/xhsAgencyTool/BatchPushToTopicModal.vue';
import moment from 'moment';
import { TIME_TYPE_LIST } from './constant';
import { trackRequest } from '@/utils/track';

export default {
  name: 'knowledgeStore',
  components: { writtingDrawer, BatchPushToTopicModal },
  data() {
    return {
      TIME_TYPE_LIST,
      searchForm: {
        timeType: 'mtime',
        crawlTaskId: undefined,
        handleStatus: undefined,
      },
      pickerTime: undefined,
      pagination: {
        total: 1,
        current: 1,
        pageSize: 30,
      },
      tableLoading: false,
      dataList: [],
      selectedRowKeys: [],
      showDrawer: false,
      btnLoading: false,
      sortedInfo: null,
      creatorList: [],
      statusList: [
        { label: '待处理', value: 'PENDING' },
        { label: '已推送', value: 'PROCESSED' },
        { label: '已忽略', value: 'IGNORED' },
      ],
      pendingCount: 0,
    };
  },
  computed: {
    columns() {
      let { sortedInfo } = this;
      sortedInfo = sortedInfo || {};
      const columns = [
        {
          width: 300,
          title: '笔记',
          fixed: 'left',
          scopedSlots: { customRender: 'writingPreview' },
        },
        {
          title: '发布时间',
          sorter: true,
          sortOrder: sortedInfo.columnKey === 'publishTime' && sortedInfo.order,
          align: 'center',
          dataIndex: 'publishTime',
          customRender: (text) => {
            return text || '-';
          },
          width: 100,
        },
        {
          title: '互动量',
          sorter: true,
          sortOrder: sortedInfo.columnKey === 'interactionCount' && sortedInfo.order,
          align: 'center',
          dataIndex: 'interactionCount',
          width: 100,
        },
        {
          title: '点赞',
          sorter: true,
          sortOrder: sortedInfo.columnKey === 'likeCount' && sortedInfo.order,
          align: 'center',
          dataIndex: 'likeCount',
          width: 100,
        },
        {
          title: '评论',
          sorter: true,
          sortOrder: sortedInfo.columnKey === 'commentCount' && sortedInfo.order,
          align: 'center',
          dataIndex: 'commentCount',
          width: 100,
        },
        {
          title: '收藏',
          sorter: true,
          sortOrder: sortedInfo.columnKey === 'collectCount' && sortedInfo.order,
          align: 'center',
          dataIndex: 'collectCount',
          width: 100,
        },
        {
          title: '分享',
          sorter: true,
          sortOrder: sortedInfo.columnKey === 'shareCount' && sortedInfo.order,
          align: 'center',
          dataIndex: 'shareCount',
          width: 100,
        },
        {
          title: '笔记编辑',
          sorter: true,
          sortOrder: sortedInfo.columnKey === 'lastUpdateTime' && sortedInfo.order,
          align: 'center',
          dataIndex: 'lastUpdateTime',
          customRender: (text) => {
            return text || '-';
          },
          width: 100,
        },
        {
          title: '数据更新',
          sorter: true,
          sortOrder: sortedInfo.columnKey === 'mtime' && sortedInfo.order,
          align: 'center',
          dataIndex: 'mtime',
          customRender: (text) => {
            return text || '-';
          },
          width: 120,
        },
        {
          title: '添加时间',
          sorter: true,
          sortOrder: sortedInfo.columnKey === 'ctime' && sortedInfo.order,
          align: 'center',
          dataIndex: 'ctime',
          customRender: (text) => {
            return text || '-';
          },
          width: 120,
        },
        {
          title: '添加人',
          align: 'center',
          dataIndex: 'creator',
          customRender: (text) => {
            return text || '-';
          },
          width: 120,
        },
        {
          title: '处理状态',
          align: 'center',
          dataIndex: 'handleStatus',
          scopedSlots: { customRender: 'handleStatus' },
          width: 120,
        },
        {
          align: 'center',
          title: '操作',
          width: 180,
          fixed: 'right',
          scopedSlots: { customRender: 'operation' },
        },
      ];
      return columns;
    },
    rowSelection() {
      const { selectedRowKeys } = this;
      return {
        selectedRowKeys,
        onChange: this.onSelectChange,
      };
    },
  },
  created() {
    if (this.$route.query.crawlTaskId) this.searchForm.crawlTaskId = this.$route.query.crawlTaskId;
    this.getCreatorList();
    this.getDataList();
  },
  methods: {
    getStatusText(status) {
      return (status && this.statusList.find((v) => v.value === status)?.label) || '-';
    },
    getPendingCount() {
      this.handleRequest('getPendingNoteNum', (data) => (this.pendingCount = data));
    },
    getDataList() {
      this.getPendingCount();
      const startTime = this.pickerTime ? `${moment(this.pickerTime).format('YYYY-MM-DD')} 00:00:00` : null;
      const endTime = this.pickerTime ? `${moment(this.pickerTime).format('YYYY-MM-DD')} 23:59:59` : null;

      const params = {
        ...this.searchForm,
        startTime,
        endTime,
        page: this.pagination.current,
        size: this.pagination.pageSize,
      };
      this.tableLoading = true;
      this.handleRequest(
        'getKnowledgeList',
        (data) => {
          this.dataList = data.list;
          this.pagination.total = data.total;
        },
        params
      ).finally(() => (this.tableLoading = false));
    },
    getCreatorList() {
      this.handleRequest('getKnowledgeCreatorList', (data) => {
        this.creatorList = data;
      });
    },
    onSearch() {
      this.selectedRowKeys = [];
      this.pagination.current = 1;
      this.getDataList();
    },
    onReset() {
      this.pickerTime = undefined;
      this.sortedInfo = null;
      this.searchForm = this.$options.data().searchForm;
      this.onSearch();
    },
    handlePaginationChange(current, pageSize) {
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getDataList();
    },
    onTableChange(pagination, filters, sorter) {
      this.sortedInfo = sorter;
      const { order, field } = sorter;
      if (order) {
        this.searchForm.sortFieldList = [
          {
            fieldName: field,
            orderType: order === 'descend' ? 'desc' : 'asc',
          },
        ];
      } else {
        this.searchForm.sortFieldList = undefined;
      }
      this.getDataList();
    },
    // 移除管理
    handleDel(id) {
      this.handleTrack('delete_note');

      this.handleRequest(
        'handleKnowledgeDel',
        () => {
          this.$message.success('操作成功');
          if (this.dataList.length == 1 && this.pagination.current > 1) this.pagination.current--;
          this.getDataList();
        },
        [id]
      );
    },
    confirmMove() {
      if (!this.selectedRowKeys.length) return this.$message.info('请至少选择一条数据');
      this.btnLoading = true;
      this.handleRequest(
        'handleKnowledgeDel',
        () => {
          this.$message.success('操作成功');
          this.selectedRowKeys = [];
          this.getDataList();
        },
        this.selectedRowKeys
      ).finally(() => (this.btnLoading = false));
    },
    // 批量推送
    handleBatchPush(val) {
      this.handleTrack('push_to_topic');
      let dataList = [];
      if (val.id) {
        dataList.push({
          id: val.id,
          noteId: val.noteId,
          noteTitle: val.noteTitle,
        });
      } else {
        this.dataList.forEach((val) => {
          for (let i of this.selectedRowKeys) {
            if (val.id == i) {
              dataList.push({
                id: val.id,
                noteId: val.noteId,
                noteTitle: val.noteTitle,
              });
            }
          }
        });
      }
      if (!dataList.length) return this.$message.info('请至少选择一篇笔记');
      this.$refs['BatchPushToTopicModal'].openModal({
        type: '笔记',
        dataList,
        createSource: 'KNOWLEDGE',
      });
    },
    // 推送成功
    handlePushSuccess() {
      this.selectedRowKeys = [];
      this.getDataList();
    },
    // 批量忽略
    handleBatchIgnore({ id }) {
      const idList = [];
      if (id) {
        idList.push(id);
      } else {
        this.selectedRowKeys.forEach((val) => {
          idList.push(val);
        });
      }
      if (!idList.length) return this.$message.info('请至少选择一条数据');
      this.btnLoading = true;
      this.handleRequest(
        'handleIgnoreNote',
        () => {
          this.$message.success('操作成功');
          this.selectedRowKeys = [];
          this.getDataList();
        },
        idList
      ).finally(() => (this.btnLoading = false));
    },
    // 小红书主页跳转
    async handleJumpXHS(value) {
      const url = `https://www.xiaohongshu.com/user/profile/${value.authorId}`;
      window.open(url, '_blank');
    },
    async handleRequest(APINAME, callbackFn, PARAMS = null) {
      const { code, data, message } = await api[APINAME](PARAMS);
      if (code === 200) {
        callbackFn(data);
      } else {
        return this.$message.error(`${message}`);
      }
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    onCopy() {
      this.$message.success('复制成功');
    },
    onCopyError() {
      this.$message.error('复制失败，该浏览器不支持自动复制');
    },
    openCheckWriting(value) {
      this.showDrawer = true;
      this.knowledgeId = value.id;
      this.handleTrack('view_note_detail', { note_id: value.noteId });
    },
    handleDrawerClose() {
      this.showDrawer = false;
      this.knowledgeId = undefined;
    },
    handleTrack(trackEvent, trackParams = {}) {
      const to = this.$route;
      trackRequest(to, trackEvent, trackParams);
    },
    handleTipsClick() {
      this.pickerTime = undefined;
      this.sortedInfo = null;
      this.searchForm = this.$options.data().searchForm;
      this.searchForm.handleStatus = 'PENDING';
      this.onSearch();
    },
  },
};
</script>

<style lang="less" scoped>
::v-deep .ant-alert-message {
  color: inherit;
}

.filter-wrapper {
  margin-bottom: 15px;
}

.writingPreview {
  display: flex;
  cursor: pointer;

  .previewLeft {
    margin-right: 5px;
    width: 60px;
    height: 80px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .previewRight {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .rightTitle {
      width: 200px;
      font-size: 600;
      color: #000;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .rightId {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #ae7983;

      .rightIdL {
        width: 165px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .media-info {
      display: flex;
      align-items: center;
      .logo {
        width: 20px;
        height: 20px;
        border-radius: 10px;
        overflow: hidden;
        margin: 0;
        margin-right: 5px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .media-name {
        color: rgba(0, 0, 0, 0.55);
        font-size: 12px;
      }
    }
  }
}

.media-column {
  display: flex;
  margin-top: 10px;
  text-align: left;

  .media-left {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .media-right {
    flex: 1;
    cursor: pointer;

    .right-txt {
      justify-content: start;

      .logo {
        width: 20px;
        height: 20px;
        border-radius: 10px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

.blue-text {
  color: #1890ff;
  cursor: pointer;
}

.table-line {
  margin: 10px 0;
  padding: 5px 20px;
  border-radius: 5px;
  background-color: #e6f7ff;
  border: 1px solid #8fc9ff;
}

.line-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nickname-text {
  margin-left: 5px;
  width: 160px;
  color: #888888;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/deep/ .ant-table {
  font-size: 12px;
}
</style>
